import React, { useEffect, useState, createRef, lazy, Suspense } from "react"
import { Helmet } from "react-helmet"
import LayoutTeam from "../components/layoutteam"
import Navigation from "../components/navigation"
import { Link } from "gatsby"

import "../styles/hybrid.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
import SimpleSlider from "../components/slider"
import SEOHeader from "../components/seo-header"
import teamLogo from "../img/worqpass.svg"
import lottie from "lottie-web"
import VizSensor from "react-visibility-sensor"
import animation from "../animations/newhomeanimation.json"

import HybridReportImg from "../img/Report-Hybrid.jpg"
import "lazysizes"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import Img from "gatsby-image"

import BlogCard from "../components/blog/blog-card"
import CaseStudies from "../components/case-studies"
import HomeSearchJourney from "../components/search/homesearchjourney"
export default function Home(props) {
  const [viewportWidth, setViewportWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth)
    }

    // Initial width on component mount
    setViewportWidth(window.innerWidth)

    // Event listener for resize
    window.addEventListener("resize", handleResize)

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const homeBanner = props.data.allBanners.edges
  // Function to get a random element from an array
  function getRandomElement(array) {
    const randomIndex = Math.floor(Math.random() * array.length)
    return array[randomIndex]
  }

  // Get a random element from homeBanner
  const randomBanner = getRandomElement(homeBanner)

  const [imgViz, setimgViz] = useState(false)
  const [imgVizdisplay, setimgVizdisplay] = useState(false)
  const [teamBanner, setteamBanner] = useState(false)
  const [unlockDeals, setunlockDeals] = useState(false)
  const [testimonial, settestimonial] = useState(false)
  const [bookCoworking, setbookCoworking] = useState(false)
  let animationContainer = createRef()
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => {
      animationContainer.current = animationContainer.current || {}
      anim.destroy(animationContainer.current)
    } // optional clean up for unmounting
  }, [])

  return (
    <div>
      <SEOHeader
        title="GoFloaters: Book Coworking Spaces for work and meetings"
        description="Looking for coworking, shared office, or meeting space? Book spaces by the hour or by the day or monthly. Book instantly ⚡ from a range of workspaces across 40+ cities and save at least 25%."
        socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
        pinterest="true"
      ></SEOHeader>
      <Helmet>
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org/",
          "@type": "Organization",
          "name": "GoFloaters",
          "url":"https://gofloaters.com/",
          "logo":"https://assets.gofloaters.com/icons/gofloaters_logo.png",
          "sameAs":["https://www.facebook.com/gofloatersindia", "https://www.instagram.com/gofloatersapp", "https://twitter.com/gofloaters", "https://www.youtube.com/channel/UCy_PdgFKsHuZY_hZ2r8gQvg","https://www.linkedin.com/company/gofloaters/"],
          "contactPoint":{
            "@type":"ContactPoint",
            "telephone":"+91 7338 7308 00",
            "contactType":"Customer Service"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.6",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": "Gofloaters App Users"
            }
          }
        }`}
        </script>
        <script type="application/ld+json">
          {` {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://www.gofloaters.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.gofloaters.com/sitesearch/?query={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }`}
        </script>
      </Helmet>
      <LayoutTeam>
        <div id="hometopmoving">
          <Navigation />
          <div
            className={"GofloaterHomeSearchDynamic "}
            style={
              viewportWidth > 768
                ? {
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundImage: `url(${randomBanner.node.image})`,
                  }
                : {}
            }
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="searchBox">
                    <h1>
                      Work Better, Together: Book Your Workspace or Meeting Room
                    </h1>
                    <p>
                      Get instant access to thousands of workspaces and meeting
                      rooms
                    </p>
                    <HomeSearchJourney />
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
              <div className="row DesktopOnly">
                <div className="col-md-8"></div>
                <div className="col-md-4 text-right">
                  <br />

                  <p
                    style={{
                      color: "#303030",
                    }}
                  >
                    <span
                      style={{
                        background: "rgba(255,255,255,.9)",
                        padding: "5px 10px",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                      }}
                    >
                      {randomBanner.node.spaceName}
                    </span>
                  </p>
                  <p
                    style={{
                      color: "#303030",
                    }}
                  >
                    <span
                      style={{
                        background: "rgba(255,255,255,.9)",
                        padding: "5px 10px",
                      }}
                    >
                      {randomBanner.node.spaceLocation}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="GofloaterHomeSearchImage">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="searchBox">
                    <h1>
                      Work Better, Together: Book Your Workspace or Meeting Room
                    </h1>
                    <p>
                      Get instant access to thousands of workspaces and meeting
                      rooms
                    </p>
                    <NewHomeSearch />
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
            <div className="MobileOnly">
              <img src={HomeBannerAlter} className="img-responsive" />
            </div>
          </div> */}

          {/* Home Banner End */}
          <div className="container-fluid" id="calltoaction">
            <div className="row  GofloaterHomeCoworking">
              <div className="col-md-12 text-center gofloaterHomenothing">
                <h2>Our unique and affordable office solutions</h2>

                <br></br>
              </div>

              <div className="col-md-3">
                <div className="HomeCTACard">
                  <img src="https://assets.gofloaters.com/homepage/new/meeting-rooms-by-the-hour.jpg"></img>
                  <h5>Meeting rooms by the hour</h5>
                  <p>
                    Right sized room at the right price for any kind of meeting
                    from interviews to presentations.
                  </p>
                  <Link to="/meeting-spaces" className="searchWorkspace">
                    Check Options
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="HomeCTACard">
                  <img src="https://assets.gofloaters.com/homepage/new/hybrid-workplace.jpg"></img>
                  <h5>Hybrid Workplace</h5>
                  <p>
                    Your remote and distributed teams can now gain instant
                    access to 2000+ professional, productive coworking spaces
                    across 45 cities in India on a pay-per-use model.
                  </p>
                  <Link to="/worqflexi/" className="searchWorkspace">
                    Explore Solution
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="HomeCTACard">
                  <img src="https://assets.gofloaters.com/homepage/new/virtual-offices.jpg"></img>
                  <h5>Virtual Offices</h5>
                  <p>
                    Get an address to register your company or just as a
                    communication address. Service available across 24 cities at
                    rates starting Rs 1000 / month.
                  </p>
                  <Link to="/virtual-office/" className="searchWorkspace">
                    Check Pricing
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="HomeCTACard">
                  <img src="https://assets.gofloaters.com/homepage/new/hot-desks-for-a-day.jpg"></img>
                  <h5>Hot desks for a day</h5>
                  <p>
                    Book a desk for a day at rates starting Rs 99/day. Work near
                    home for heads-down work.
                  </p>
                  <Link to="/coworking-spaces" className="searchWorkspace">
                    Check Options
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="GofloaterHome">
            <div className="container-fluid" style={{ padding: "0px" }}>
              <div className="row">
                <div className="col-md-3 col-3 DesktopOnly">
                  {/* <picture>
                  <source
                    srcSet="https://assets.gofloaters.com/webp/trust-left.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="https://assets.gofloaters.com/teams/newhome/trust-left.png"
                    type="image/jpeg"
                  />
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/trust-left.png"
                    alt="Trust Right"
                    className="lazyload gofloaterhomeleft"
                  ></img>
                </picture>*/}
                </div>
                <div className="col-md-6 gofloatershometext gofloatershometrusttext">
                  <div className="container">
                    <div className="row ">
                      <div className="col-md-12 nocolpadding">
                        <p>Trusted by 30,000+ customers including</p>
                      </div>
                      <br></br>
                      <div className="col-md-3 col-3 text-center img-hteams fivePixelPadding">
                        <img
                          data-src="https://assets.gofloaters.com/teams/chargebee.svg"
                          alt="Chargebee"
                          className="lazyload"
                          width="180"
                        ></img>
                      </div>
                      <div className="col-md-3 col-3 text-center img-hteams fivePixelPadding">
                        <img
                          data-src="https://assets.gofloaters.com/clients/notion-press.png"
                          alt="Notion Press"
                          className="lazyload"
                          width="182"
                          height="46"
                        ></img>
                      </div>
                      <div className="col-md-3 col-3 text-center img-hteams fivePixelPadding">
                        <img
                          data-src="https://assets.gofloaters.com/teams/acko.png"
                          alt="acko logo"
                          className="lazyload"
                          width="182"
                          height="46"
                        ></img>
                      </div>
                      <div className="col-md-3 col-3 text-center img-hteams fivePixelPadding">
                        <img
                          data-src="https://assets.gofloaters.com/teams/spi-edges.png"
                          alt="SPI Edeges"
                          className="lazyload"
                          width="180"
                        ></img>
                      </div>
                      <div className="col-md-3 col-3 text-center img-hteams fivePixelPadding noLeftPadding">
                        <img
                          data-src="https://assets.gofloaters.com/teams/decalthaonlogo.svg"
                          alt="Decalthaon"
                          className="lazyload"
                          width="182"
                          height="46"
                        ></img>
                      </div>
                      <div className="col-md-3 col-3 text-center img-hteams fivePixelPadding">
                        <img
                          data-src="https://assets.gofloaters.com/teams/indusind.png"
                          alt="Indusind"
                          className="lazyload"
                          width="177"
                          height="21"
                        ></img>
                      </div>
                      <div className="col-md-3 col-3 text-center img-hteams fivePixelPadding">
                        <img
                          data-src="https://assets.gofloaters.com/teams/nippon.png"
                          alt="Nippon"
                          className="lazyload"
                          width="177"
                          height="50"
                        ></img>
                      </div>
                      <div className="col-md-3 col-3 text-center img-hteams fivePixelPadding">
                        <img
                          data-src="https://assets.gofloaters.com/teams/homelane.png"
                          alt="Homelane"
                          className="lazyload"
                          width="177"
                          height="44"
                        ></img>
                      </div>
                      <div className="col-3 MobileOnly"></div>
                      <div className="col-md-3 col-3 text-center img-hteams fivePixelPadding">
                        <img
                          data-src="https://assets.gofloaters.com/clients/shriram.png"
                          alt="Shriram"
                          className="lazyload"
                          width="177"
                          height="53"
                        ></img>
                      </div>

                      <div className="col-md-3  col-3 text-center img-hteams fivePixelPadding">
                        <img
                          data-src="https://assets.gofloaters.com/worqpass/flintobox.png"
                          alt="Mind & Moms"
                          className="lazyload"
                          width="177"
                          height="48"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="col-md-3 col-6 MobileOnly">
                <picture>
                  <source
                    srcSet="https://assets.gofloaters.com/webp/trust-left.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="https://assets.gofloaters.com/teams/newhome/trust-left.png"
                    type="image/jpeg"
                  />
                  <img
                    src="https://assets.gofloaters.com/teams/newhome/trust-left.png"
                    alt="Trust Right"
                    className="gofloaterhomeleft"
                  ></img>
                </picture>
              </div>
              <div className="col-md-3 col-6 text-right">
                <picture>
                  <source
                    srcSet="https://assets.gofloaters.com/webp/trust-right.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="https://assets.gofloaters.com/teams/newhome/trust-right.png"
                    type="image/jpeg"
                  />
                  <img
                    src="https://assets.gofloaters.com/teams/newhome/trust-right.png"
                    alt="Trust Right"
                    className="gofloaterhomeright"
                  ></img>
                </picture>
  </div> */}
              </div>
            </div>
          </div>
        </div>
        <VizSensor
          partialVisibility
          onChange={isVisible => {
            setbookCoworking(isVisible)
            if (!bookCoworking) {
              setbookCoworking(isVisible)
            }
          }}
        >
          <div className="container" id="howwork">
            <div className="row teamPadding GofloaterHomeCoworking">
              <div className="col-md-6 DesktopOnly">
                <BrowserView>
                  <Img
                    fluid={props.data.CoworkingBanner.childImageSharp.fluid}
                    className="lazyload img-responsive"
                    alt="Book a coworking space"
                  ></Img>
                </BrowserView>
              </div>
              <div className="col-md-6">
                <h2>Book a coworking space</h2>
                <p>
                  GoFloaters helps you find and book a coworking space near you
                  for meetings and work.
                </p>
                <div className="points">
                  <div className="row">
                    <div className="col-md-2 col-3 text-center">
                      <img
                        src="https://assets.gofloaters.com/teams/newhome/pay-per-use.svg"
                        alt="Pay per Use"
                        width="45"
                        height="44"
                      ></img>
                    </div>
                    <div className="col-md-10 col-9">
                      <h4>Pay-per-use</h4>
                      <p>
                        No contracts, or commitments.
                        <br></br>Book for an hour or a day.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="points">
                  <div className="row">
                    <div className="col-md-2 col-3 text-center">
                      <img
                        src="https://assets.gofloaters.com/teams/newhome/plug-and-play.svg"
                        alt="plug and play"
                        width="45"
                        height="44"
                      ></img>
                    </div>
                    <div className="col-md-10 col-9">
                      <h4>Plug and play</h4>
                      <p>
                        Prices inclusive of all amenities
                        <br></br>
                        Prices starting ₹199/day
                      </p>
                    </div>
                  </div>
                </div>
                <div className="points">
                  <div className="row">
                    <div className="col-md-12">
                      <a
                        onClick={() => scrollTo("#hometopmoving")}
                        className=" searchWorkspace"
                      >
                        Search Workspaces
                      </a>
                      <br></br>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VizSensor>
        <VizSensor
          partialVisibility
          onChange={isVisible => {
            settestimonial(isVisible)
            if (!testimonial) {
              settestimonial(isVisible)
            }
          }}
        >
          <div
            style={{
              background: "#F6F9FF",
            }}
            id="signup"
          >
            <div className="container">
              <div className="row teamPadding">
                <div className="col-md-12 text-center gofloaterHomenothing">
                  <h2>Nothing can beat a face-to-face</h2>
                  <p>Don’t take our word for it</p>
                  <br></br>
                </div>
                <div className="col-md-4">
                  <div className="HomeTestimonailCard">
                    <div className="hometextWrapper">
                      <h4>Freedom to set up my office</h4>
                      <p>
                        Gives me the freedom to set up my office across cities
                      </p>
                    </div>
                    <span className="MarkersHomeTestimonailCard"></span>
                    <div className="homelogoWrapper">
                      <picture>
                        <source
                          srcSet="https://assets.gofloaters.com/webp/vinay-004.webp"
                          type="image/webp"
                        />
                        <source
                          data-src="https://assets.gofloaters.com/teams/newhome/vinay-004.jpg"
                          type="image/jpeg"
                          className="lazyload"
                        />
                        <img
                          data-src="https://assets.gofloaters.com/teams/newhome/vinay-004.jpg"
                          alt="Vinay Pushpakaran"
                          className="lazyload"
                          width="75"
                          height="75"
                        ></img>
                      </picture>

                      <p className="text-center">Vinay Pushpakaran</p>
                      <span>Future Impact Learning</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="HomeTestimonailCard">
                    <div className="hometextWrapper">
                      <h4>Plug and Play</h4>
                      <p>Never have to worry about Internet or Plug Points.</p>
                    </div>
                    <span className="MarkersHomeTestimonailCard"></span>
                    <div className="homelogoWrapper">
                      <picture>
                        <source
                          srcSet="https://assets.gofloaters.com/webp/arpita-002.webp"
                          type="image/webp"
                        />
                        <source
                          data-src="https://assets.gofloaters.com/teams/newhome/arpita-002.jpg"
                          type="image/jpeg"
                          className="lazyload"
                        />
                        <img
                          data-src="https://assets.gofloaters.com/teams/newhome/arpita-002.jpg"
                          className="lazyload"
                          alt="Arpitha Ramani"
                          width="75"
                          height="75"
                        ></img>
                      </picture>

                      <p className="text-center">Arpitha Ramani </p>
                      <span>GE Healthcare</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="HomeTestimonailCard">
                    <div className="hometextWrapper">
                      <h4>External meeting made easy</h4>
                      <p>My go-to platform for all our external meetings</p>
                    </div>
                    <span className="MarkersHomeTestimonailCard"></span>
                    <div className="homelogoWrapper">
                      <picture>
                        <source
                          srcSet="https://assets.gofloaters.com/webp/balesh-001.webp"
                          type="image/webp"
                        />
                        <source
                          data-src="https://assets.gofloaters.com/teams/newhome/balesh-001.jpg"
                          className="lazyload"
                          type="image/jpeg"
                        />
                        <img
                          data-src="https://assets.gofloaters.com/teams/newhome/balesh-001.jpg"
                          className="lazyload"
                          width="75"
                          height="75"
                          alt="Balesh Raghurajan"
                        ></img>
                      </picture>

                      <p className="text-center">Balesh Raghurajan</p>
                      <span>Effilor Consulting Services</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VizSensor>
        <div className="TeamHomeBanner">
          <div className="container">
            <div className="row teamPadding">
              <div className="col-md-4 TeamHomeBannerleft">
                <h2
                  style={{
                    fontWeight: "bold",
                    lineHeight: "1.3",
                  }}
                >
                  Introducing<br></br>
                  WorqFlexi
                </h2>
                <br></br>
                <img
                  data-src={teamLogo}
                  width="250"
                  height="63"
                  className="lazyload"
                  alt="Gofloater WorqFlexi"
                ></img>
                <br></br> <br></br>
                <p>
                  Save more than 60% from your current office rental contract by
                  offering pay-per-use office space for your remote team.
                </p>
                <Link to="/worqflexi/" className="searchWorkspace">
                  Show Me How
                </Link>
                <br></br>
                <br></br>
              </div>
              <div className="col-md-6">
                <div className="GoflaoterHomeRight">
                  <div className="row">
                    <div className="col-md-8">
                      <h4>SIGN UP</h4>
                      <p>
                        Sign up on our platform within 3 minutes. No credit card
                        required.
                      </p>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-8">
                      <h4>CHOOSE WORKSPACES</h4>
                      <p>
                        Curate from 3000+ high quality spaces in 40+ cities
                        across India
                      </p>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-8">
                      <h4>PAY AS YOU GO</h4>
                      <p>
                        Set monthly budget. Pay as you go. Manage access. Track
                        utilization
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 introImage">
                <div className="animation-container" ref={animationContainer} />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <CaseStudies />
          </div>
        </div>

        {/* <VizSensor
          partialVisibility
          onChange={isVisible => {
            setunlockDeals(isVisible)
            if (!unlockDeals) {
              setunlockDeals(isVisible)
            }
          }}
        >
          <div className="" id="unlockDeals">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2>Unlock exclusive deals when you sign up</h2>
                  <p>
                    We offer ₹1.5 Crores in free benefits and discounts for
                    eligible startups* through our partner program
                  </p>
                </div>
              </div>
              <div className="row MobileOnly">
                <div className="col-6">
                  <div className="partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                      className="lazyload height"
                      alt="todoist"
                      width="108"
                      height="30"
                    ></img>
                    <p>1 free year of Business plan</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                      className="lazyload width"
                      alt="miro"
                      width="90"
                      height="32"
                    ></img>
                    <p>$1000 in credits</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                      className="lazyload height"
                      alt="aws"
                      width="50"
                      height="30"
                    ></img>
                    <p>$1000 credit for 2 years</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                      className="lazyload width"
                      alt="razorpay"
                      width="90"
                      height="19"
                    ></img>
                    <p>Zero transaction fee upto ₹4L</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                      className="lazyload height"
                      alt="hubspot"
                      width="103"
                      height="30"
                    ></img>
                    <p>90% off on yearly plans</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                      className="lazyload height"
                      alt="udemy"
                      width="103"
                      height="30"
                    ></img>
                    <p>Upto 95% off on courses</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                      className="lazyload height"
                      alt="segment"
                      width="108"
                      height="30"
                    ></img>
                    <p>
                      $50,000 in credits + discounts on 35+ products worth $1M
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                      className="lazyload height"
                      alt="typeform"
                      width="47"
                      height="30"
                    ></img>
                    <p>50% of monthly & yearly plans</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                      className="lazyload height"
                      alt="kaleyra"
                      width="124"
                      height="30"
                    ></img>
                    <p>₹8000 credit for 1 year</p>
                  </div>
                </div>
              </div>
              <div className="row gofloaterHomePartner DesktopOnly">
                <div className="col-md-1"></div>
                <div className="col-md-2">
                  <div className="column1 partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                      className="lazyload height"
                      alt="todoist"
                      width="108"
                      height="30"
                    ></img>
                    <p>1 free year of Business plan</p>
                  </div>
                </div>
                <div className="col-md-2 columns2">
                  <div className=" column2 partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                      className="lazyload width"
                      alt="miro"
                      width="90"
                      height="32"
                    ></img>
                    <p>$1000 in credits</p>
                  </div>
                  <div className="column2 partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                      className="lazyload height"
                      alt="aws"
                      width="50"
                      height="30"
                    ></img>
                    <p>$1000 credit for 2 years</p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="column3 partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                      className="lazyload width"
                      alt="razorpay"
                      width="90"
                      height="19"
                    ></img>
                    <p>Zero transaction fee upto ₹4L</p>
                  </div>
                  <div className="column3 partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                      className="lazyload height"
                      alt="hubspot"
                      width="103"
                      height="30"
                    ></img>
                    <p>90% off on yearly plans</p>
                  </div>
                  <div className="column3 partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                      className="lazyload height"
                      alt="udemy"
                      width="103"
                      height="30"
                    ></img>
                    <p>Upto 95% off on courses</p>
                  </div>
                </div>
                <div className="col-md-2 columns2">
                  <div className=" column4 partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                      className="lazyload height"
                      alt="segment"
                      width="108"
                      height="30"
                    ></img>
                    <p>
                      $50,000 in credits + discounts on 35+ products worth $1M
                    </p>
                  </div>
                  <div className="column4 partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                      className="lazyload height"
                      alt="typeform"
                      width="47"
                      height="30"
                    ></img>
                    <p>50% of monthly & yearly plans</p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="column5 partnerOffer">
                    <img
                      data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                      className="lazyload height"
                      alt="kaleyra"
                      width="124"
                      height="30"
                    ></img>
                    <p>₹8000 credit for 1 year</p>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>&nbsp;</p>
                  <Link
                    to="/our-community-perks/"
                    title="Our Community Perks"
                    style={{ color: "#303030", fontWeight: "bold" }}
                  >
                    ...and many more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </VizSensor> */}
        {/* <div className="gofloaterHomeApp">
          <div className="container">
            <div className="row aligner">
              <div className="col-md-6 DesktopOnly">
                <BrowserView>
                  <video
                    width="100%"
                    className="dc_video_vd"
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source
                      src="https://assets.gofloaters.com/gofloaters_download_app.mp4"
                      type="video/mp4"
                    />
                  </video>
                </BrowserView>
              </div>

              <div className="col-md-6 homepage">
                <br></br>
                <h2
                  className="h1"
                  style={{
                    color: "#303030",
                  }}
                >
                  Download the app
                </h2>
                <p
                  style={{
                    color: "#303030",
                  }}
                >
                  We’re consistently rated above 4.6 stars on Play Store & App
                  Store for quality workspaces and proactive customer support.
                  See for yourself, download now.
                </p>
                <br></br>
                <br></br>
                <p>
                  <a
                    href="https://bit.ly/GoFloatersPlayStore"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src="https://assets.gofloaters.com/google-play-gofloaters-download.png"
                      alt="Gofloaters Google Play"
                      width="150"
                      height="45"
                    />{" "}
                  </a>
                  &nbsp;
                  <a
                    href="https://itunes.apple.com/in/app/gofloaters/id1446010118?mt=8&uo=4&at=11l6hc&app=itunes&ct=fnd"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <img
                      src="https://assets.gofloaters.com/gofloater-apple-store.png"
                      alt="Gofloaters Apple Store"
                      width="150"
                      height="45"
                    />{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <VizSensor
          partialVisibility
          onChange={isVisible => {
            setimgViz(isVisible)
            if (!imgVizdisplay) {
              setimgVizdisplay(isVisible)
            }
          }}
        >
          <div className="container padding-60">
            <div className="row homepage">
              <div className="col-md-12 text-center">
                <h2 className="h1">Featured Blogs</h2>
              </div>
              <BlogCard></BlogCard>
            </div>
          </div>
        </VizSensor>
        <div className="container padding-60">
          <div className="row homepage">
            <div className="col-md-12 text-center">
              <h2 className="h1">Featured in</h2>
            </div>

            <div className="col-md-12">
              <SimpleSlider></SimpleSlider>
            </div>
          </div>
        </div>
        <div className="container padding-60">
          <div className="row homepage hydridFormDownload">
            <div className="col-md-5">
              <img src={HybridReportImg} className="img-responsive"></img>
            </div>
            <div className="col-md-7">
              <br></br>
              <h2
                style={{
                  fontWeight: "bold",
                  fontSize: "1.8em",
                  paddingBottom: "15px",
                }}
              >
                Unleashing the power of hybrid - remote with Insights from
                customers tractions
              </h2>
              <h3
                style={{
                  fontWeight: "400",
                  fontSize: "1.2em",
                }}
              >
                State of hybrid work - Trend report 2022 & 2023
              </h3>
              <br />
              <Link to="/hybrid-report" className="btn btn-default">
                Download Now
              </Link>
            </div>
          </div>
        </div>
      </LayoutTeam>
    </div>
  )
}

export const query = graphql`
  query HomeImagesNew {
    kaleyra: file(relativePath: { eq: "teams/newhome/logos/kaleyra.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    typeform: file(relativePath: { eq: "teams/newhome/logos/typeform.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vinay: file(relativePath: { eq: "teams/newhome/vinay-004.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arpita: file(relativePath: { eq: "teams/newhome/arpita-002.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    balesh: file(relativePath: { eq: "teams/newhome/balesh-001.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    CoworkingBanner: file(
      relativePath: { eq: "teams/newhome/coworking-spaces.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allBanners {
      edges {
        node {
          image
          spaceLocation
          spaceName
        }
      }
    }
  }
`
