import React, { useState, useEffect } from "react"
import Autocomplete from "react-google-autocomplete"
import { navigate } from "gatsby"
import SearchForm from "./search"

const HomeSearchJourney = props => {
  const [spacetype, setspacetype] = useState("meetingSpace")
  const [gpsLocation, setgpsLocation] = useState(false)
  const [firsturl, setfirsturl] = useState(
    "/search/?city=Bengaluru&lat=12.968367&lng=77.5953411&spaceType=meetingSpace"
  )
  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          // Will return ['granted', 'prompt', 'denied']
          //console.log(result.state)
          if (result.state != "denied") {
            setgpsLocation(true)
          } else {
            setgpsLocation(false)
          }
        })
    } else {
      setgpsLocation(false)
    }
  }, [])

  const [url, seturl] = useState("")

  const doSomething = place => {
    let address = place.formatted_address,
      location = address.split(",")[0],
      city = address.split(",")[1],
      state = address.split(",")[2],
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng()
    location = location.trim()
    setfirsturl(
      "/search?city=" +
        location +
        ", " +
        city +
        ", " +
        state +
        "&lat=" +
        latValue +
        "&lng=" +
        lngValue +
        "&spaceType="
    )
  }

  const getGEO = place => {
    if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
      window.gtag("event", "Near Me Space", {
        event_category: "Button Click",
        event_label: "Near Me",
        value: "2",
      })
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      setgpsLocation(true)
      console.log(position.coords.latitude, position.coords.longitude)
      var url =
        "/search?city=Nearby Spaces" +
        "&lat=" +
        position.coords.latitude +
        "&lng=" +
        position.coords.longitude +
        "&spaceType=" +
        spacetype
      navigate(url)
    })
  }

  function changeSpaceType(e) {
    setspacetype(e.target.value)
  }

  const SearchResult = () => {
    // seturl(firsturl)
    // // console.log("url", url)
    navigate(firsturl + spacetype)
  }
  return (
    <div>
      <div className="homePageSearch">
        <div style={{ display: "inline" }}>
          <div className="input-group-search-home panel-search">
            <div className="has-search item ">
              <label htmlFor="SearchLocation">Find the Space..</label>
              <span
                class="fa fa-map-marker form-control-feedback"
                style={{ lineHeight: "2.3" }}
              ></span>
              <Autocomplete
                className="form-control homesearchformnew paddingRight"
                placeholder={
                  props.placeholder ? props.placeholder : "Your Location"
                }
                name="SearchLocation"
                onPlaceSelected={place => {
                  doSomething(place)
                }}
                types={["establishment" | "cities"]}
                options={{
                  componentRestrictions: { country: "in" },
                }}
                offset="3"
              />
            </div>
            <select
              className="form-control item  homesearchformnew paddingRight"
              onChange={changeSpaceType}
            >
              <option value="meetingSpace"> Meeting Space</option>
              <option value="dailyofficeSpace"> Coworking Day Pass</option>
              <option value="monthlyofficeSpace"> Monthly Office Space</option>
            </select>
            <button
              onClick={SearchResult}
              className="NewHomeSearch"
              value="Search"
            >
              <i className="fa fa-search"></i> <span> Start</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomeSearchJourney
